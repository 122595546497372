import { tv, VariantProps } from '@/utils/tv'

export const hero = tv({
  slots: {
    base: ['relative', 'overflow-hidden'],
    wrapper: [
      'relative z-20',
      'grid items-center',
      'h-full',
      'px-6 lg:px-8 pt-28 pb-12',
      'max-w-7xl',
      'mx-auto'
    ],
    preTitle: ['mb-2'],
    title: ['max-w-3xl'],
    description: ['max-w-3xl mt-5'],
    buttonGroup: ['mt-10'],
    figure: [
      'md:absolute',
      'z-0 top-0 left-0',
      'w-full md:h-full',
      'aspect-w-1 aspect-h-1 md:aspect-none'
    ],
    image: ['object-cover'],
    videoWrapper: [
      'hidden md:block',
      'w-full h-full',
      'absolute inset-0',
      'bg-black z-10',
      'before:content-[""]',
      'before:absolute before:inset-0',
      'before:bg-gray-900/70',
      'before:w-full before:h-full before:z-10'
    ],
    videoContainer: ['h-full w-full'],
    video: ['object-cover bg-black'],
    logos: ['flex items-center justify-center gap-10 xs:gap-14', 'mb-6'],
    brandWrapper: [
      'relative',
      '[&:not(:last-child)]:after:absolute',
      '[&:not(:last-child)]:after:inset-y-0',
      '[&:not(:last-child)]:after:-right-5 xs:[&:not(:last-child)]:after:-right-7',
      '[&:not(:last-child)]:after:m-auto',
      '[&:not(:last-child)]:after:content-[""]',
      '[&:not(:last-child)]:after:bg-white',
      '[&:not(:last-child)]:after:w-[1px]',
      '[&:not(:last-child)]:after:h-6 xs:[&:not(:last-child)]:after:h-9'
    ],
    brand: ['w-auto h-5 xs:h-7']
  },
  variants: {
    theme: {
      dark: {
        base: 'bg-black',
        preTitle: 'text-acqua-500',
        description: 'text-gray-40'
      },
      light: {
        base: 'bg-white',
        preTitle: 'text-acqua-900',
        description: 'text-gray-60'
      }
    },
    align: {
      left: {
        wrapper: ['gap-8 md:grid-cols-2']
      },
      center: {
        wrapper: ['md:text-center md:justify-center']
      }
    },
    isFull: {
      false: {
        wrapper: ['md:py-32']
      },
      true: {
        base: ['md:min-h-[720px] md:h-screen'],
        wrapper: ['md:py-10']
      }
    }
  },
  defaultVariants: {
    theme: 'dark',
    align: 'left',
    isFull: true
  }
})

export type HeroVariantProps = VariantProps<typeof hero>

export type HeroVariantReturnProps = ReturnType<typeof hero>
